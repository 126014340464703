const API = 'https://api.agentgo.com.br';

export const environment = {
  production: true,
  API: API,
  PAYPAL_CLIENT_ID: "",ambiente: "AGENTGO",
  THEME: "AGENTGO", //(FRT - ARKBEDS - FRT-BLACK)
  //Módulos de turismo
  VALIDAPRAZOCANCELAMENTOMANUTENCAO : false,
  USA_CASAMENTO: false,
  USA_CONTRATO: false,
  USA_CIRCUITO: false,
  USA_TURISMO: true,
  USA_PACOTE: false,
  USA_SEGURO: false,
  USA_DISNEY: true,
  USA_CARRO: false,
  USA_AEREO: true,
  USA_LOJA: false,
  USA_CASA: false,
  USA_HOTEL: true,
  USA_BETO: false,
  USA_PROMOCIONAL: false,
  USA_MARKUPIMPRESSAO: false,
  USA_CADASTROAGENCIA: true,
  USA_ARKBEDS: false,
 USA_TERMOMETRO: true,
  USA_SOLICITARDESCONTO: true,
  //Apis dos módulos de turismo
  TURISMO: API,
  TURISMO_AEREO: API,
  TURISMO_HOTEL: API,
  TURISMO_SERVICO: API,
  TURISMO_SEGURO: API,
  GERENCIAMENTO: API,
  TURISMO_CARRO: API,
  EMPRESAS: API,

  //Módulos Adicionais
  USA_BLOQUEIO_FRT: false, // Permite o uso de um botão adicional na aba bloqueios
  USA_INTRANET: false, // Permite o uso da Intranet no portal
  USA_LAMINAS: true, // Permite Gerar Lâminas no portal
  USA_BANNERS: false, //Permite o uso de Banners

  //Configurações
  MAPBOX_ACCESSTOKEN: "",
  TAG_GOOGLE: "",
  USA_PACOTESIMPLIFICADO: false,
  CLIENT_ID: "postman:123",
  ITENSPORPAGINA: 20,
  MAXIMO_QUARTOS: 8,
  URLSITE: ""
};
